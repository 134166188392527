@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Inter";
}

html,
body,
#root {
  background: #171717;
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background: theme("colors.neutral.600");
  border-radius: theme("borderRadius.sm");
}

::-webkit-scrollbar-track {
  background: theme("colors.neutral.900");
}